<template>
  <MdEditor 
    v-model="text"
    language="en-US" />
</template>

<script setup>
import { ref } from 'vue';
import { MdEditor } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { useStoriesStore } from '@/stores/stories';
const storiesStore = useStoriesStore();
const route = useRoute()
const text = ref('# Hello Editor');

const fetchData = async () => {
  const data  = await storiesStore.getStory(route.params.id)
  console.log(data);

  useNuxtApp().$customEventBus.$emit("loading", false)
};

onMounted(() => {
  fetchData();
});
</script>